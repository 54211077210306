<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {}
  },
  created() {
    document.title = '晟友云码CRM';
  }
};
</script>

<style></style>
